require('./bootstrap');

setTimeout(function () {
  $(".flash .alert-success, .flash .alert-danger, .flash .alert-error")
    .fadeOut(1000)
    .queue(function () {
      this.remove();
    });
}, 1000);


$('#navbarDropdown').on('click', function () {
  $('#navbarDropdown').toggleClass('show');
  $('#navbarDropdown').next().toggleClass('show');
});